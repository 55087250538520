var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "loc-tos-modal",
      attrs: {
        id: "loc-tos-modal",
        "hide-footer": "",
        size: "lg",
        scrollable: "",
        "header-class": "no-borders",
      },
      on: { hidden: _vm.hide },
    },
    [
      _c(
        "div",
        {
          ref: "loc-tos-modal-container",
          staticClass: "loc-tos-modal-container",
        },
        [
          _c("div", {
            staticClass: "loc-tos-modal-content",
            domProps: { innerHTML: _vm._s(_vm.sanitizedTosDocContent) },
          }),
          _c(
            "a",
            {
              attrs: {
                id: "loc-authorized-badge-disclosure",
                href: "https://utahinnovationoffice.org/sandbox-customer-complaint/",
                target: "_blank",
              },
            },
            [
              _c("picture", [
                _c("source", {
                  attrs: {
                    srcset: require("@images/authority-disclosure-badge.webp"),
                    type: "image/webp",
                  },
                }),
                _c("img", {
                  attrs: {
                    src: require("@images/authority-disclosure-badge.png"),
                    alt: "authority-disclosure-badge",
                  },
                }),
              ]),
            ]
          ),
          _c(
            "div",
            { staticClass: "pb-4 mb-4" },
            [
              _c("p", [
                _c("strong", [
                  _vm._v(
                    "BY CLICKING THE BOX BELOW, YOU ACKNOWLEDGE THAT YOUR HAVE READ, UNDERSTAND, AND AGREE TO BE BOUND BY THE TERMS ABOVE."
                  ),
                ]),
              ]),
              _c(
                "b-checkbox",
                {
                  directives: [
                    { name: "explicit-checked", rawName: "v-explicit-checked" },
                  ],
                  ref: "agreement-checkbox",
                  staticClass: "mb-3 mt-3",
                  on: {
                    change: function ($event) {
                      return _vm.agreementChanged($event)
                    },
                  },
                  model: {
                    value: _vm.agreedToTermsOfService,
                    callback: function ($$v) {
                      _vm.agreedToTermsOfService = $$v
                    },
                    expression: "agreedToTermsOfService",
                  },
                },
                [_vm._v("\n        I have Read and Agree\n      ")]
              ),
              _c("div", { staticClass: "mb-2" }, [
                _vm.showSignaturePad
                  ? _c("span", [_vm._v("Please sign here:")])
                  : _vm._e(),
              ]),
              _vm.showSignaturePad
                ? _c(
                    "b-row",
                    {
                      ref: "signature-pad-container",
                      staticClass:
                        "shadow border border-info rounded mt-4 ml-4 mb-5",
                      staticStyle: { width: "75%", height: "400%" },
                    },
                    [
                      _c("vue-signature-pad", {
                        ref: "signature-pad",
                        attrs: {
                          width: "100%",
                          height: "100%",
                          options: { backgroundColor: "rgba(248,249,250,1)" },
                        },
                        on: { "hook:mounted": _vm.scrollDown },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _c(
        "b-row",
        { staticClass: "float-right" },
        [
          _vm.showSignaturePad
            ? _c(
                "b-button",
                {
                  staticClass: "mb-2 mr-3",
                  attrs: { variant: "secondary", "aria-label": "clear button" },
                  on: {
                    click: function ($event) {
                      return _vm.clearSignature()
                    },
                  },
                },
                [_vm._v("\n      Clear\n    ")]
              )
            : _vm._e(),
          _c(
            "b-button",
            {
              staticClass: "mb-2",
              attrs: {
                variant: "primary",
                "aria-label": "continue button",
                disabled: !_vm.agreedToTermsOfService,
              },
              on: {
                click: function ($event) {
                  return _vm.submit()
                },
              },
            },
            [_vm._v("\n      Continue\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }