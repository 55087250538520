var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "legal-request-component" },
    [
      !_vm.loaded
        ? _c("ct-centered-spinner")
        : !_vm.requestComplete
        ? _c(
            "div",
            { staticClass: "flex-wrap" },
            [
              _c("masthead", {
                attrs: {
                  "super-title": "Law On Call",
                  title: "New Legal Request",
                  "show-search": false,
                },
              }),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "8" } },
                    [
                      _c(
                        "b-form",
                        { staticClass: "fields form-group" },
                        [
                          _c("validation-observer", {
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ invalid }) {
                                  return [
                                    _c(
                                      "b-form-group",
                                      { attrs: { label: "Company" } },
                                      [
                                        _c("company-select", {
                                          attrs: {
                                            companies:
                                              _vm.companiesWithDistinctServiceType(
                                                "law-on-call"
                                              ),
                                          },
                                          on: { input: _vm.setCompanyId },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm.jurisdictions.length > 1 &&
                                    _vm.context.companyId
                                      ? _c(
                                          "b-form-group",
                                          { attrs: { label: "Jurisdiction" } },
                                          [
                                            _c("b-select", {
                                              attrs: {
                                                value:
                                                  _vm.selectedJurisdictionName,
                                                options: _vm.jurisdictions,
                                                required: "",
                                              },
                                              on: {
                                                change:
                                                  _vm.updateSelectedJurisdiction,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.selectedJurisdictionId &&
                                    _vm.context.companyId
                                      ? _c(
                                          "b-form-group",
                                          { attrs: { label: "Category" } },
                                          [
                                            _c("b-select", {
                                              attrs: {
                                                options: _vm.categories,
                                                required: "",
                                              },
                                              on: {
                                                change:
                                                  _vm.updateSelectedCategory,
                                              },
                                              model: {
                                                value: _vm.selectedCategoryName,
                                                callback: function ($$v) {
                                                  _vm.selectedCategoryName = $$v
                                                },
                                                expression:
                                                  "selectedCategoryName",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.subcategories.length > 0
                                      ? _c(
                                          "b-form-group",
                                          { attrs: { label: "Subcategory" } },
                                          [
                                            _c("b-select", {
                                              attrs: {
                                                options: _vm.subcategories,
                                              },
                                              on: {
                                                change:
                                                  _vm.updateSelectedSubcategory,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.specs.length > 0
                                      ? _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: "Legal Request Types",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-form-radio-group",
                                              {
                                                attrs: {
                                                  size: "lg",
                                                  name: "spec-radios",
                                                  stacked: "",
                                                  required: "",
                                                },
                                                model: {
                                                  value: _vm.selectedSpec,
                                                  callback: function ($$v) {
                                                    _vm.selectedSpec = $$v
                                                  },
                                                  expression: "selectedSpec",
                                                },
                                              },
                                              _vm._l(
                                                _vm.specs,
                                                function (spec) {
                                                  return _c(
                                                    "b-form-radio",
                                                    {
                                                      key: spec.title,
                                                      staticClass:
                                                        "juicy-radio spec-radio",
                                                      attrs: {
                                                        value: spec,
                                                        required: "",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "label-content",
                                                        },
                                                        [
                                                          _c("div", [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "title",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    spec.title
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "average-time",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                        – Average Time: " +
                                                                    _vm._s(
                                                                      spec.estimated_hours
                                                                        ? `${spec.estimated_hours} hours`
                                                                        : "Varies"
                                                                    ) +
                                                                    "\n                      "
                                                                ),
                                                              ]
                                                            ),
                                                          ]),
                                                          _c("div", {
                                                            staticClass:
                                                              "description",
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                _vm.sanitizeHTML(
                                                                  spec.description ||
                                                                    "Specify the type of legal request you need."
                                                                )
                                                              ),
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                      _vm.customSpecSelected &&
                                                      spec.title === "Other"
                                                        ? _c(
                                                            "validation-provider",
                                                            {
                                                              attrs: {
                                                                rules:
                                                                  "required",
                                                                name: "Description",
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "default",
                                                                      fn: function ({
                                                                        errors,
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "b-form-input",
                                                                            {
                                                                              staticClass:
                                                                                "custom-spec",
                                                                              attrs:
                                                                                {
                                                                                  type: "text",
                                                                                  maxlength:
                                                                                    "52",
                                                                                  placeholder:
                                                                                    "Description (required)",
                                                                                  required:
                                                                                    "",
                                                                                },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    _vm.customTitle,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.customTitle =
                                                                                        $$v
                                                                                    },
                                                                                  expression:
                                                                                    "customTitle",
                                                                                },
                                                                            }
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "error",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  errors[0]
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                            }
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.selectedSpec
                                      ? _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label:
                                                "Attorney Experience Level",
                                            },
                                          },
                                          [
                                            _c("validation-provider", {
                                              attrs: {
                                                rules: "required",
                                                name: "Attorney Experience Level",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function ({ errors }) {
                                                      return [
                                                        _c(
                                                          "b-form-radio-group",
                                                          {
                                                            attrs: {
                                                              size: "lg",
                                                              name: "attorney-experience-level-radios",
                                                              required: "",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.selectedAttorneyExperienceLevel,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.selectedAttorneyExperienceLevel =
                                                                    $$v
                                                                },
                                                              expression:
                                                                "selectedAttorneyExperienceLevel",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "attorneyExperienceLevelRadiosContainer",
                                                              },
                                                              _vm._l(
                                                                _vm.attorneyExperienceLevels,
                                                                function (
                                                                  expLevel,
                                                                  key
                                                                ) {
                                                                  return _c(
                                                                    "b-form-radio",
                                                                    {
                                                                      key: key,
                                                                      staticClass:
                                                                        "juicy-radio horz attorney-experience-level-radio",
                                                                      attrs: {
                                                                        value:
                                                                          expLevel,
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "label-content",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "title",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                          " +
                                                                                  _vm._s(
                                                                                    expLevel.range
                                                                                  ) +
                                                                                  "\n                        "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "hourlyRate",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                          $" +
                                                                                  _vm._s(
                                                                                    expLevel.hourly_rate
                                                                                  ) +
                                                                                  " / hr\n                        "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "error",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(errors[0])
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.selectedSpec
                                      ? [
                                          _vm.customSpecSelected ||
                                          _vm.estimatedHoursUnknown
                                            ? [
                                                _c(
                                                  "b-row",
                                                  {
                                                    staticStyle: {
                                                      "margin-bottom": "0",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "b-form-group",
                                                      {
                                                        staticClass: "col-3",
                                                        attrs: {
                                                          label:
                                                            "Maximum Hours*",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "template",
                                                          {
                                                            slot: "description",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                      Enter the number of work hours you authorize for this Legal Request.\n                    "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "validation-provider",
                                                          {
                                                            attrs: {
                                                              rules: "required",
                                                              name: "Maximum Hours",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "default",
                                                                  fn: function ({
                                                                    errors,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "b-form-input",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              min: "0",
                                                                              max: "1001",
                                                                              state:
                                                                                !errors[0],
                                                                              type: "number",
                                                                              required:
                                                                                "",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm.hoursRequested,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.hoursRequested =
                                                                                    $$v
                                                                                },
                                                                              expression:
                                                                                "hoursRequested",
                                                                            },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "error",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              errors[0]
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          }
                                                        ),
                                                      ],
                                                      2
                                                    ),
                                                    _c(
                                                      "b-form-group",
                                                      {
                                                        staticClass: "col-3",
                                                        attrs: {
                                                          label:
                                                            "Estimated Cost",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "template",
                                                          {
                                                            slot: "description",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                      The estimated cost for your legal fees.\n                    "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "estimated-cost",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                      " +
                                                                _vm._s(
                                                                  _vm.estimatedCost
                                                                ) +
                                                                "\n                    "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      2
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c("b-row", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-12 estimated-cost-fine-print",
                                                    },
                                                    [
                                                      _c("small", [
                                                        _vm._v(
                                                          "*Pricing for this category varies depending upon the length and content of the\n                      requested document. Pricing will be determined after initial consultation with\n                      an attorney. Nevertheless, you may select an approximate amount of time for\n                      which you would like our attorneys to work on your project. Thereafter, during\n                      consultation with the attorney, a different time and pricing selection may be\n                      made, giving weight to your pre-selected, desired time."
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ]),
                                              ]
                                            : [
                                                _c(
                                                  "b-row",
                                                  [
                                                    _c(
                                                      "b-form-group",
                                                      {
                                                        staticClass: "col-6",
                                                        attrs: {
                                                          label:
                                                            "Estimated Cost",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "template",
                                                          {
                                                            slot: "description",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                      The estimated cost for your legal fees.\n                    "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "estimated-cost",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                      " +
                                                                _vm._s(
                                                                  _vm.estimatedCost
                                                                ) +
                                                                "\n                    "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      2
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                        ]
                                      : _vm._e(),
                                    _c(
                                      "b-row",
                                      [
                                        _vm.selectedSpec
                                          ? _c(
                                              "b-form-group",
                                              {
                                                staticClass: "col-12",
                                                attrs: {
                                                  label: "Additional Details",
                                                },
                                              },
                                              [
                                                _c(
                                                  "template",
                                                  { slot: "description" },
                                                  [
                                                    _vm._v(
                                                      "\n                  Enter any additional details relevant to the work requested.\n                "
                                                    ),
                                                  ]
                                                ),
                                                _c("validation-provider", {
                                                  attrs: {
                                                    rules: "max:1000",
                                                    name: "Details",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function ({
                                                          errors,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "input-group",
                                                              },
                                                              [
                                                                _c(
                                                                  "b-form-textarea",
                                                                  {
                                                                    staticClass:
                                                                      "additional-details",
                                                                    attrs: {
                                                                      type: "text",
                                                                      maxlength:
                                                                        _vm.additionalDetailsMaxLength,
                                                                      placeholder:
                                                                        "Details (optional)",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.additionalDetails,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.additionalDetails =
                                                                            $$v
                                                                        },
                                                                      expression:
                                                                        "additionalDetails",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c("div", {
                                                              staticClass:
                                                                "input-group-addon",
                                                              domProps: {
                                                                textContent:
                                                                  _vm._s(
                                                                    `${
                                                                      _vm.additionalDetailsMaxLength -
                                                                      _vm
                                                                        .additionalDetails
                                                                        .length
                                                                    } characters remaining.`
                                                                  ),
                                                              },
                                                            }),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "error",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    errors[0]
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                }),
                                              ],
                                              2
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-button",
                                      {
                                        staticClass:
                                          "btn btn-primary float-right",
                                        attrs: {
                                          disabled:
                                            invalid ||
                                            !_vm.selectedSpec ||
                                            _vm.addingItemToCart,
                                        },
                                        on: { click: _vm.addSpecToCart },
                                      },
                                      [
                                        _vm._v(
                                          "\n              Add to Cart\n            "
                                        ),
                                      ]
                                    ),
                                    _c("span", { staticClass: "error" }, [
                                      _vm._v(_vm._s(_vm.errors[0])),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "float-right",
                      staticStyle: { margin: "5px", flex: "1 0 20%" },
                      attrs: { cols: "4" },
                    },
                    [
                      _c("cart", {
                        attrs: {
                          "button-text": "Submit Order",
                          "cart-type-scope": _vm.cartTypeScope,
                          "processing-checkout": _vm.legalRequestSubmitStarted,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c("div", { staticClass: "legal-request-complete-container" }, [
            _c(
              "div",
              { staticClass: "legal-request-complete" },
              [
                _c("h3", { staticStyle: { "text-align": "center" } }, [
                  _vm._v(
                    "\n        Your Legal Request Order has been submitted.\n      "
                  ),
                ]),
                _c("fa-icon", {
                  staticStyle: {
                    color: "#ddd",
                    "font-size": "96px",
                    margin: "20px 0",
                  },
                  attrs: { icon: "inbox" },
                }),
                _c(
                  "b-button",
                  {
                    staticClass: "btn btn-preferred new-legal-request-button",
                    attrs: { variant: "primary" },
                    on: { click: _vm.reset },
                  },
                  [
                    _c("feather-icon", { attrs: { type: "plus" } }),
                    _vm._v("\n        Submit Another Order\n      "),
                  ],
                  1
                ),
                _c("div", { staticClass: "row-or" }, [
                  _c("div", [_vm._v("or")]),
                ]),
                _c(
                  "div",
                  { staticClass: "continue" },
                  [
                    _vm._v("\n        Continue to \n        "),
                    _c("b-link", { attrs: { to: "/law-on-call" } }, [
                      _vm._v("\n          Law On Call\n        "),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
      _c("law-on-call-terms-of-service-modal", {
        ref: "loc-tos-modal",
        attrs: { "tos-doc-content": _vm.currentTosDocContent },
        on: {
          agreed: _vm.submitLegalRequests,
          "close-tos": _vm.resetLegalRequestSubmit,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }