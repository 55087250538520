<template>
  <div class="legal-request-component">
    <ct-centered-spinner v-if="!loaded" />
    <div v-else-if="!requestComplete" class="flex-wrap">
      <masthead
        super-title="Law On Call"
        title="New Legal Request"
        :show-search="false"
      />
      <b-row>
        <b-col cols="8">
          <b-form class="fields form-group">
            <validation-observer v-slot="{ invalid }">
              <b-form-group label="Company">
                <company-select
                  :companies="companiesWithDistinctServiceType('law-on-call')"
                  @input="setCompanyId"
                />
              </b-form-group>

              <!-- Show Jurisdiction drop down if client has LOC service in more than one jurisdiction (Jurisdiction will be auto-filled for clients with only one)-->
              <b-form-group v-if="jurisdictions.length > 1 && context.companyId" label="Jurisdiction">
                <b-select
                  :value="selectedJurisdictionName"
                  :options="jurisdictions"
                  required
                  @change="updateSelectedJurisdiction"
                />
              </b-form-group>

              <b-form-group v-if="selectedJurisdictionId && context.companyId" label="Category">
                <b-select
                  v-model="selectedCategoryName"
                  :options="categories"
                  required
                  @change="updateSelectedCategory"
                />
              </b-form-group>

              <b-form-group v-if="subcategories.length > 0" label="Subcategory">
                <b-select
                  :options="subcategories"
                  @change="updateSelectedSubcategory"
                />
              </b-form-group>

              <b-form-group v-if="specs.length > 0" label="Legal Request Types">
                <b-form-radio-group
                  v-model="selectedSpec"
                  size="lg"
                  name="spec-radios"
                  stacked
                  required
                >
                  <b-form-radio
                    v-for="spec in specs"
                    :key="spec.title"
                    :value="spec"
                    class="juicy-radio spec-radio"
                    required
                  >
                    <div class="label-content">
                      <div>
                        <span class="title">{{ spec.title }}</span>
                        <span class="average-time">
                          – Average Time: {{ spec.estimated_hours ? `${spec.estimated_hours} hours` : 'Varies' }}
                        </span>
                      </div>
                      <div class="description" v-html="sanitizeHTML(spec.description || 'Specify the type of legal request you need.')" />
                    </div>

                    <validation-provider
                      v-if="customSpecSelected && spec.title === 'Other'"
                      v-slot="{ errors }"
                      rules="required"
                      name="Description"
                    >
                      <b-form-input
                        v-model="customTitle"
                        class="custom-spec"
                        type="text"
                        maxlength="52"
                        placeholder="Description (required)"
                        required
                      />
                      <span class="error">{{ errors[0] }}</span>
                    </validation-provider>
                  </b-form-radio>
                </b-form-radio-group>
              </b-form-group>

              <b-form-group v-if="selectedSpec" label="Attorney Experience Level">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="Attorney Experience Level"
                >
                  <b-form-radio-group
                    v-model="selectedAttorneyExperienceLevel"
                    size="lg"
                    name="attorney-experience-level-radios"
                    required
                  >
                    <div class="attorneyExperienceLevelRadiosContainer">
                      <b-form-radio
                        v-for="(expLevel,key) in attorneyExperienceLevels"
                        :key="key"
                        :value="expLevel"
                        class="juicy-radio horz attorney-experience-level-radio"
                      >
                        <div class="label-content">
                          <div class="title">
                            {{ expLevel.range }}
                          </div>
                          <div class="hourlyRate">
                            ${{ expLevel.hourly_rate }} / hr
                          </div>
                        </div>
                      </b-form-radio>
                    </div>
                  </b-form-radio-group>
                  <span class="error">{{ errors[0] }}</span>
                </validation-provider>
              </b-form-group>

              <template v-if="selectedSpec">
                <template v-if="customSpecSelected || estimatedHoursUnknown">
                  <b-row style="margin-bottom: 0;">
                    <b-form-group
                      class="col-3"
                      label="Maximum Hours*"
                    >
                      <template slot="description">
                        Enter the number of work hours you authorize for this Legal Request.
                      </template>
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                        name="Maximum Hours"
                      >
                        <b-form-input
                          v-model="hoursRequested"
                          min="0"
                          max="1001"
                          :state="!errors[0]"
                          type="number"
                          required
                        />
                        <span class="error">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      class="col-3"
                      label="Estimated Cost"
                    >
                      <template slot="description">
                        The estimated cost for your legal fees.
                      </template>
                      <div class="estimated-cost">
                        {{ estimatedCost }}
                      </div>
                    </b-form-group>
                  </b-row>
                  <b-row>
                    <div class="col-12 estimated-cost-fine-print">
                      <small>*Pricing for this category varies depending upon the length and content of the
                        requested document. Pricing will be determined after initial consultation with
                        an attorney. Nevertheless, you may select an approximate amount of time for
                        which you would like our attorneys to work on your project. Thereafter, during
                        consultation with the attorney, a different time and pricing selection may be
                        made, giving weight to your pre-selected, desired time.</small>
                    </div>
                  </b-row>
                </template>
                <template v-else>
                  <b-row>
                    <b-form-group
                      class="col-6"
                      label="Estimated Cost"
                    >
                      <template slot="description">
                        The estimated cost for your legal fees.
                      </template>
                      <div class="estimated-cost">
                        {{ estimatedCost }}
                      </div>
                    </b-form-group>
                  </b-row>
                </template>
              </template>

              <b-row>
                <b-form-group v-if="selectedSpec" class="col-12" label="Additional Details">
                  <template slot="description">
                    Enter any additional details relevant to the work requested.
                  </template>
                  <validation-provider
                    v-slot="{ errors }"
                    rules="max:1000"
                    name="Details"
                  >
                    <div class="input-group">
                      <b-form-textarea
                        v-model="additionalDetails"
                        class="additional-details"
                        type="text"
                        :maxlength="additionalDetailsMaxLength"
                        placeholder="Details (optional)"
                      />
                    </div>
                    <div
                      class="input-group-addon"
                      v-text="`${additionalDetailsMaxLength - additionalDetails.length} characters remaining.`"
                    />
                    <span class="error">{{ errors[0] }}</span>
                  </validation-provider>
                </b-form-group>
              </b-row>

              <b-button
                class="btn btn-primary float-right"
                :disabled="invalid || !selectedSpec || addingItemToCart"
                @click="addSpecToCart"
              >
                Add to Cart
              </b-button>
              <span class="error">{{ errors[0] }}</span>
            </validation-observer>
          </b-form>
        </b-col>

        <b-col style="margin: 5px; flex: 1 0 20%" class="float-right" cols="4">
          <cart :button-text="'Submit Order'" :cart-type-scope="cartTypeScope" :processing-checkout="legalRequestSubmitStarted" />
        </b-col>
      </b-row>
    </div>

    <div v-else class="legal-request-complete-container">
      <div class="legal-request-complete">
        <h3 style="text-align: center;">
          Your Legal Request Order has been submitted.
        </h3>
        <fa-icon icon="inbox" style="color: #ddd; font-size: 96px; margin: 20px 0;" />

        <b-button
          variant="primary"
          class="btn btn-preferred new-legal-request-button"
          @click="reset"
        >
          <feather-icon type="plus" />
          Submit Another Order
        </b-button>

        <div class="row-or">
          <div>or</div>
        </div>
        <div class="continue">
          Continue to&nbsp;
          <b-link :to="'/law-on-call'">
            Law On Call
          </b-link>
        </div>
      </div>
    </div>
    <law-on-call-terms-of-service-modal
      ref="loc-tos-modal"
      :tos-doc-content="currentTosDocContent"
      @agreed="submitLegalRequests"
      @close-tos="resetLegalRequestSubmit"
    />
  </div>
</template>

<script>
import FeatherIcon from '@/components/shared/FeatherIcon'
import Masthead from '@/components/shared/Masthead'
import { mapGetters, mapActions } from 'vuex'
import Cart from '@/components/HireUs/Cart'
import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner'
import CompanySelect from '@/components/CompanySelect'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import LawOnCallTermsOfServiceModal from '@/components/LawOnCallTermsOfServiceModal'
import axiosClient from '@/http'
import * as DOMPurify from 'dompurify'

export default {
  name: 'LegalRequest',
  components: {
    LawOnCallTermsOfServiceModal,
    Masthead,
    Cart,
    CompanySelect,
    CtCenteredSpinner,
    FeatherIcon,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      loaded: false,
      tosCount: 0,
      tosData: {
        company_id: '',
        documents: [],
      },
      currentTosDocContent: '',
      jurisdictions: [],
      categories: [],
      subcategories: [],
      specs: [],
      selectedJurisdictionName: '',
      selectedJurisdictionId: '',
      selectedCategoryName:'',
      selectedCategory: {},
      selectedSubcategory: {},
      selectedSpec: null,
      selectedAttorneyExperienceLevel: null,
      hoursRequested: null,
      customTitle: null,
      additionalDetails: '',
      additionalDetailsMaxLength: 1001,
      requestComplete: false,
      legalRequestSubmitStarted: false,
      addingItemToCart: false,
      attorneyExperienceLevels: [
        { id: 0, range: '10+ years',  hourly_rate: '250' },
        { id: 1, range: '8-10 years', hourly_rate: '200' },
        { id: 2, range: '4-7 years',  hourly_rate: '150' },
        { id: 3, range: '1-3 years',  hourly_rate: '99'  },
      ],
      cartTypeScope: 'LegalSpec',
      errors: [],
    }
  },
  computed: {
    ...mapGetters('dashpanel', [
      'activeLawOnCallServices',
    ]),
    ...mapGetters('lawOnCall', [
      'legalSpecs',
      'tosDocs',
    ]),
    ...mapGetters('companies', [
      'companiesWithDistinctServiceType',
    ]),
    ...mapGetters('checkout', [
      'context',
      'cartItems',
    ]),
    lawOnCallServiceJurisdictions() {
      return this.activeLawOnCallServices.map(s => s.stateProvinceRegion)
    },
    lawOnCallServiceJurisdictionIds() {
      return this.activeLawOnCallServices.map(s => s.jurisdictionId)
    },
    customSpecSelected() {
      return this.selectedSpec?.title === 'Other'
    },
    estimatedHoursUnknown() {
      return this.selectedSpec?.estimated_hours === null
    },
    estimatedCost() {
      const hours = parseFloat(this.selectedSpec?.estimated_hours || this.hoursRequested)
      const rate = this.selectedAttorneyExperienceLevel?.hourly_rate
      const cost = hours * rate
      return isNaN(cost) ? '—' : `$${cost}`
    },
  },
  async mounted() {
    this.loaded = false
    await this.loadLegalSpecs({ jurisdictionIds: this.lawOnCallServiceJurisdictionIds })
    this.jurisdictions = this.lawOnCallServiceJurisdictions
    if(this.jurisdictions.length === 1) {
      this.setJurisdiction(this.jurisdictions[0])
      this.setCategories(this.legalSpecs[this.selectedJurisdictionId])
    }
    this.selectedAttorneyExperienceLevel = this.attorneyExperienceLevels[0]
    this.$root.$on('cart-next-page', this.showTermsOfServiceModal)
    this.resetState()
    this.loaded = true
  },
  methods: {
    ...mapActions('lawOnCall', [
      'loadLegalSpecs',
      'loadTosDocs',
    ]),
    ...mapActions('checkout', [
      'addToCart',
      'loadCartItems',
      'setupContext',
      'resetState',
    ]),
    getJurisdictionId(state) {
      return this.activeLawOnCallServices.find(s => s.stateProvinceRegion == state).jurisdictionId
    },
    async setCompanyId(companyId) {
      const cartTypeScope = 'LegalSpec'
      await this.setupContext({ companyId: companyId, cartTypeScope: this.cartTypeScope, productKind: cartTypeScope })
    },
    setJurisdiction(jurisdiction) {
      this.selectedJurisdictionName = jurisdiction
      this.selectedJurisdictionId = this.getJurisdictionId(jurisdiction)
    },
    setCategories(categories) {
      this.categories = this.mapObjectAsAlphaSortedOptions(categories)
    },
    setSubcategories(subcategories) {
      this.subcategories = this.mapObjectAsAlphaSortedOptions(subcategories)
    },
    setSpecs(specs) {
      this.specs = this.alphaSort(specs, 'title', 'Other')
    },
    mapObjectAsAlphaSortedOptions(obj) {
      const options = Object.entries(obj).map((entry) => {
        return { text: entry[0], value: entry[1] }
      })
      return this.alphaSort(options, 'text')
    },
    alphaSort(arr, key, demote=null) {
      let array = arr.sort((a, b) => {
        const aKey = a[key].toLowerCase()
        const bKey = b[key].toLowerCase()
        return (aKey < bKey) ? -1 : (aKey > bKey) ? 1 : 0
      })
      if (demote) { this.demoteElementByKey(array, key, demote) }
      return array
    },
    demoteElementByKey(array, key, demote) {
      array.map((e, i) => {
        if (e[key].toLowerCase() === demote.toLowerCase()) {
          array.splice(i, 1)
          array.push(e)
        }
      })
    },
    updateSelectedJurisdiction(jurisdiction) {
      this.resetSelectionsDeeperThan('category')
      this.setJurisdiction(jurisdiction)
      this.setCategories(this.legalSpecs[this.selectedJurisdictionId])
      if(this.selectedCategory) {
        if(this.selectedCategory?.subcategories) {
          this.specs = []
          this.selectedSubcategory = {}
          this.subcategories = []
        }
        this.selectedCategory = {}
        this.selectedCategoryName = ''
      }
    },
    updateSelectedCategory(category) {
      this.resetSelectionsDeeperThan('category')
      this.selectedCategory = category
      if (this.selectedCategory?.subcategories) {
        this.specs = []
        this.setSubcategories(this.selectedCategory.subcategories)
      } else if (this.selectedCategory) {
        this.subcategories = []
        this.setSpecs(this.selectedCategory.specs)
      }
    },
    updateSelectedSubcategory(subcategory) {
      this.resetSelectionsDeeperThan('subcategory')
      this.selectedSubcategory = subcategory
      this.setSpecs(this.selectedSubcategory.specs)
    },
    resetSelectionsDeeperThan(selection) {
      const orderedSelections = ['category', 'subcategory', 'spec', 'experienceLevel', 'authorizedTime']
      const resettable = orderedSelections.filter((v, i) => i > orderedSelections.indexOf(selection))
      resettable.forEach(selection => {
        selection = selection.charAt(0).toUpperCase() + selection.slice(1)
        const dataValue = `selected${selection}`
        this[dataValue] = null
      })
    },
    async showTermsOfServiceModal() {
      try {
        if (!this.tosCount) {
          //Pull all unique states out of cart items
          this.legalRequestSubmitStarted = true
          const jurisdiction_ids_in_cart = [...new Set(this.cartItems.map(item => item.data.jurisdiction_id))]
          this.tosCount = jurisdiction_ids_in_cart.length
          await this.loadTosDocs({ jurisdictionIds: jurisdiction_ids_in_cart })
        }

        this.currentTosDocContent = this.tosDocs[this.tosCount - 1].document_template
        this.$refs['loc-tos-modal']?.show()
      }catch(_e){
        this.failToast()
      }
    },
    async addSpecToCart() {
      this.addingItemToCart = true
      const specToAdd = {
        id: this.selectedSpec.id,
        data: {
          legal_spec_id: this.selectedSpec.id,
          custom_title: this.customTitle,
          attorney_experience: this.selectedAttorneyExperienceLevel.id,
          hours_requested: this.hoursRequested,
          additional_details: this.additionalDetails,
          estimated_cost: this.estimatedCost,
          state: this.selectedJurisdictionName,
          jurisdiction_id: this.selectedJurisdictionId,
        },
        skipResolveOptionalItems: true,
      }

      await this.addToCart(specToAdd)
      this.addingItemToCart = false
      this.reset()
    },
    resetLegalRequestSubmit() {
      this.tosData.documents = []
      this.tosCount = 0
      this.legalRequestSubmitStarted = false
    },
    async submitLegalRequests(tosAgreementWorker) {
      try {
        await tosAgreementWorker.toPdf().output('datauristring').then(async (file) => {
          //Submit TOS agreement doc for each legal request made under the jurisdiction
          const jurisdiction_id = this.tosDocs[this.tosCount-1].jurisdiction_id
          const data = {
            tos_jurisdiction_id: jurisdiction_id,
            tos_agreement_file: file.split(',')[1],
          }

          this.tosData.documents.push(data)
          this.tosCount--

          if(!this.tosCount) {
            this.tosData.company_id = this.context.companyId
            await axiosClient.post('client/legal_requests', this.tosData)

            this.requestComplete = true
            await this.loadCartItems()
            await this.setupContext({ companyId: null })
          }else{
            await this.showTermsOfServiceModal()
          }
        })
      } catch (_e) {
        this.failToast()
      }
    },
    failToast() {
      const message = 'The Legal Request Order could not be submitted.'
      this.$bvToast.toast(message,{
        title: 'Error',
        variant: 'danger',
        solid: true,
        NoAutoHide: true,
      })
    },
    reset() {
      this.selectedSubcategory = {}
      this.selectedCategoryName = ''
      this.selectedCategory = {}
      this.selectedSpec = null
      this.selectedAttorneyExperienceLevel = null
      this.specs = []
      this.subcategories = []
      this.hoursRequested = null
      this.customTitle = null
      this.additionalDetails = ''
      this.tosData.documents = []
      this.tosCount = 0
      this.legalRequestSubmitStarted = false
      this.requestComplete = false
      this.errors = []

      if(this.jurisdictions.length > 1) {
        this.selectedJurisdictionName = ''
        this.selectedJurisdictionId = ''
        this.categories = []
      }
    },
    sanitizeHTML(data) {
      return DOMPurify.sanitize(data)
    },
  },
}
</script>

<style lang="scss" scoped>
.legal-request-component {
  transition: all ease .5s;
  margin: 0 auto;

  legend {
    font-size: 1.2em;
  }

  .multiselect {
    margin-top: 0 !important;
  }

  .juicy-radio {
    @include ct-ui-card-shadow;
    font-size: 1em;
    min-height: 90px;
    height: 100%;
    padding: 15px 35px 15px 50px;
    margin: 0 0 10px 0;
    width: 100%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &.horz {
      min-height: 110px;
      height: 100%;
      flex: 0 1 48%;
      margin: 5px;
    }
  }

  input.custom-spec {
    min-height: 45px !important;
    margin-top: 10px;
    border-radius: 8px;
  }

  .spec-radio {
    @include ct-ui-selectable-horizontal;
    label {
      .label-content {
        text-align: left;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        .title {
          font-weight: 800;
          font-size: 1em;
        }

        .average-time {
          font-size: .9em;
          font-style: italic;
          color: $ct-ui-font-color-secondary;
        }

        .description {
          color: #495057;
          font-size: 12px;
        }

        &, * {
          cursor: pointer;
        }
      }
    }
  }

  .attorney-experience-level-radio {
    @include ct-ui-selectable;
    label {
      .label-content {
        text-align: left;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        .title {
          font-weight: 800;
          font-size: 1em;
        }

        .range {
          font-size: .9em;
          font-style: italic;
          color: $ct-ui-font-color-secondary;
        }

        .hourlyRate {
          color: #495057;
          font-size: 12px;
        }

        &, * {
          cursor: pointer;
        }
      }
    }
  }

  .attorneyExperienceLevelRadiosContainer {
    display: flex;
    flex-wrap: wrap;
    margin: -5px;
  }

  .selection-description {
    font-size: .8em;
    color: $ct-ui-font-color-default-light;
  }

  .estimated-cost {
    font-size: 1.5em;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 6px;
  }

  .estimated-cost-fine-print {
    color: #a9a9a9;
    line-height: 16px;
  }

  .error {
    color: $danger;
    font-size: .8em;
  }

  .additional-details {
    min-height: 120px !important;
  }

  .legal-request-complete-container {
    margin-top: 80px;
    min-height: 300px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    .legal-request-complete {
      display: flex;
      flex-direction: column;
      align-items: center;
      .new-legal-request-button {
        margin: 10px;
      }
      .row-or {
        margin: 2rem 0;
        width: 35%;
        height: 1px;
        background-color: $ct-ui-font-color-secondary;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        div {
          color: $ct-ui-font-color-secondary;
          padding: 20px;
          background: white;
          text-align: center;
        }
      }
    }
  }
}
</style>
